import '../App.css';


const Home = () =>{
    return(
        <div className='Homeimg' id='home'>
            <div className='Homeimg-1'>
            <div className='homeimg-2'>

               <p>VIEW THE EXTRAORDINARY</p>
               <h2>Homes in New Jersey</h2>
               <p>Welcome to Elegant Homes, your premier real estate company located in beautiful New Jersey! We specialize in providing exceptional real estate services to home buyers, sellers, and investors in the Garden State.At Elegant Homes, we understand that buying or selling a home is one of the most significant financial transactions in your life. That's why we are committed to providing unparalleled service, expert guidance, and personalized attention to ensure a smooth and successful real estate experience for our clients.</p>
               <button className='btn-homeimg'>VIEW OUR PROPERTIES</button>

            </div>
            <div className='homeimg-3'>

            </div>
         
            </div>
        </div>
    )
}

export default Home;