
import About from './pages/about';
import Home from './pages/home';
import Footer from './components/footer';
import Contact from './pages/contact';
import Navbar from './components/navBar';
import Portfolio from './pages/portfolio';
import './App.css';


function App() {
  return (
    <div className="App">
      <Navbar />
      <Home />
      <About name="Elegant homes"/>
      <Portfolio />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
