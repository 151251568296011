import '../App.css';


const About = (props) =>{
    return(
        <div className='about-us' id='about'>
            <h1 className='h1-about-us'>About us</h1>
            <div> {props.name}</div>
            <p>Elegant Homes is a full-service real estate company that has been serving the New Jersey community for many years. We are passionate about helping people find their dream homes and have built a reputation for providing exceptional service and expertise to our clients.

Our team of experienced and knowledgeable real estate professionals is dedicated to providing personalized attention to each client and ensuring that they receive the best possible service. We understand that every client has unique needs and preferences, and we work closely with them to provide customized solutions that meet their specific requirements.

At Elegant Homes, we take pride in our commitment to excellence and our ability to deliver outstanding results. Whether you are a first-time homebuyer, an experienced investor, or looking to sell your property, our team has the expertise and resources to help you achieve your goals.

We offer a wide range of real estate services, including buying and selling homes, property management, and real estate investment consulting. We also provide comprehensive market analysis, property valuation, and marketing strategies to ensure that our clients receive the best possible return on their investment.

At Elegant Homes, we believe that our success is measured by the satisfaction of our clients. That's why we are committed to building long-term relationships based on trust, integrity, and exceptional service. Contact us today to learn more about how we can help you achieve your real estate goals.</p>
        </div>
    )
}

export default About;