import '../App.css';


const Contact = () =>{
    return(
        <div className='contact-us' id='contact'>
         <div className='contact-1'>
            <h2>Get In Touch</h2>
            <form action='https://formspree.io/f/mqkonzgr' method='POST'>
                 <label for="contact-input" name="name">Full Name<br/>
                   <input type='text' className='contact-input' placeholder='Enter your name' required/></label><br/><br/>
                   <label for="contact-input">Email<br/>
                   <input type='email' className='contact-input' placeholder='Enter your email address'  name="email"required/></label><br/><br/>
                   <label for="contact-input">Phone number<br/>
                   <input type='tel' className='contact-input' placeholder='Enter your phone number' name="phone" required/></label><br/><br/>
                   <label for="contact-text-area">Your Message (optional)<br/>
                   <textarea className='contact-input-area' name="message"></textarea></label><br/><br/>
                   <input type='submit' className='contact-submit'/>
                      
              </form>
         </div>
        </div>
        
    );
}

export default Contact;