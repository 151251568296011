import '../App.css';


const Portfolio = () =>{
    return(
        <div className='portfolio' id='portfolio'>
            <h2 className='h2-portfolio'>Portfolio</h2>
            <div className='portfolio-1'>
                <div>
                    <h2>We sell</h2>
                    <p>Looking for your dream home? Look no further than our real estate firm! We specialize in selling beautiful, high-quality homes that are perfect for families, couples, and individuals alike. Our team of experienced agents is dedicated to helping you find the perfect house to meet your unique needs and preferences.Whether you're looking for a cozy starter home, a spacious family house, or a luxurious estate, we have the perfect property for you.</p>
                    
                </div>
                <div>
                    <h2>We build</h2>
                    <p>Looking to build your dream home? Look no further than our real estate firm! We specialize in designing and building high-quality homes that are tailored to your unique needs and preferences. Our team of expert architects, engineers, and builders will work with you every step of the way to ensure that your dream home becomes a reality.We use only the finest materials and the latest construction techniques. </p>
                </div>
                <div>
                    <h2>We buy</h2>
                    <p>We understand that selling your house can be a stressful and overwhelming experience, which is why we strive to make the process as simple and straightforward as possible. We handle all the paperwork and logistics, so you don't have to worry about a thing.So if you're looking to sell your house quickly and without the hassle of traditional real estate transactions, contact us today to schedule a consultation with one of our experts.</p>
                </div>

            </div>
        </div>
    )
}

export default Portfolio;