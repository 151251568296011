import '../App.css';

const Footer = () =>{
    return(
        <div className='footer'>
           <div className='footer-1'>
              <p>PROPERTIES BY AREA</p>
           </div>
           <div className='footer-2'>
            <ul className='footer2-1'>
              <p>North Jersey</p> 
                <li>Bergen</li>
                <li>Jersey City</li>
                <li>Hackensack</li>
            </ul>
            <ul className='footer2-2'>
               <p>Central Jersey</p>
                <li>Somerset</li>
                <li>Union</li>
                <li>Brunswick</li>
            </ul>
            <ul className='footer2-3'>
               <p>Jersey Shore</p>
                <li>Ocean City</li>
            </ul>
           </div>
           <div className='footer-3'>
            <div className='footer-3-1'>
            <h3>Contact</h3>
                <p>805-565-4000
                   info@homesinsantabarbara.com
                   1255 Coast Village Rd., #102b
                    Santa Barbara, CA 93108</p>
            </div>
            <div className='footer-3-2'>
                <h3> Elegant Homes</h3>
                <p>Welcome to Elegant Homes, your premier real estate company located in beautiful New Jersey! We specialize in providing exceptional real estate services to home buyers, sellers, and investors in the Garden State</p>
            </div>
                
           </div>

           <div className='coyright'>
                coypyright@2023
           </div>
        </div>
    )
}

export default Footer;